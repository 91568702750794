import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="page-not-found">
      <h1 className="title">404</h1>
      <div className="desc">The page you're looking for was not found.</div>
      <Link to="/">
        <button className="go-back-btn btn btn-primary" type="button">
          Go Back
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
