import React, { useEffect, useRef } from 'react';
import '../../styling/Modal.css';

function ConfirmationModal({ show, onClose, onConfirm, message, title }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const body = document.querySelector('body');
    if (show) {
      body.style.overflow = 'hidden'; // Prevent scrolling of background content

      // Move focus to the first focusable element
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea'
      );
      if (focusableElements.length > 0) {
        focusableElements[0].focus();
      }

      // Trap focus inside the modal
      const handleTab = (e) => {
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        if (e.key === 'Tab') {
          if (e.shiftKey) { // Shift + Tab
            if (document.activeElement === firstFocusableElement) {
              e.preventDefault();
              lastFocusableElement.focus();
            }
          } else { // Tab
            if (document.activeElement === lastFocusableElement) {
              e.preventDefault();
              firstFocusableElement.focus();
            }
          }
        }
      };

      document.addEventListener('keydown', handleTab);

      return () => {
        document.removeEventListener('keydown', handleTab);
        body.style.overflow = ''; // Restore scrolling
      };
    } else {
      body.style.overflow = ''; // Restore scrolling
    }
  }, [show]);

  if (!show) return null; // Early return if modal is not shown

  return (
    <>
      <div 
        className="modal show" 
        tabIndex="-1" 
        role="dialog"
        aria-labelledby="confirmationModalLabel" 
        style={{ display: 'block' }} // Ensure the modal is visible
        ref={modalRef} // Attach ref to the modal
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="confirmationModalLabel">{title || 'Confirmation'}</h5>
              <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {message || 'Are you sure you want to proceed?'}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={onConfirm}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div> {/* Backdrop for visual separation */}
    </>
  );
}

export default ConfirmationModal;
