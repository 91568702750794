// src/hooks/useConfirmationModal.js
import { useState, useCallback } from 'react';

const useConfirmationModal = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const showModal = useCallback((id) => {
    setDeleteId(id);
    setShowConfirm(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowConfirm(false);
    setDeleteId(null);
  }, []);

  return { showConfirm, deleteId, showModal, closeModal };
};

export default useConfirmationModal;
