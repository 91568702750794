import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCurrentUser, logoutUser, decodeJwt } from '../util/APIUtils.js';
import { ACCESS_TOKEN } from '../constants';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [expiration, setExpiration] = useState(0);
  const [loading, setLoading] = useState(true);
  let timer; // Declare timer variable

  const loadCurrentlyLoggedInUser = async () => {
    setLoading(true);
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      await getCurrentUser(); // Fetch user data if token exists
      const { authorities, expiration, userEmail, userName } = decodeJwt();
      handleLogin(authorities, expiration, userEmail, userName);
    } catch (error) {
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (authorities, expiration, userEmail, userName) => {
    setUserRoles(authorities);
    setExpiration(expiration);
    setUserEmail(userEmail);
    setUserName(userName);
    setAuthenticated(true);
    startSessionTimer(); // Start timer on login
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      setAuthenticated(false);
      setUserRoles([]);
      setExpiration(0);
      setUserEmail(null);
      setUserName(null);
      clearTimeout(timer); // Clear timer on logout
      toast.success("You're safely logged out!");
    } catch (error) {
      toast.error("Logout failed. Please try again.");
    }
  };

  const startSessionTimer = () => {
    // Calculate the time remaining until expiration
    const timeRemaining = expiration - Date.now();
    if (timeRemaining > 0) {
      timer = setTimeout(() => {
        handleLogout(); // Logout when timer expires
      }, timeRemaining);
    }
  };

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  return (
    <AuthContext.Provider value={{ expiration, authenticated, userEmail, userName, userRoles, loading, handleLogout, handleLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
