import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import JobForm from './JobForm';
import Chat from './Discussion/Chat';
import '../../styling/JobContainer.css'; // Import the CSS file
import { useApiClient } from '../../service/API';
import { useAuth } from '../../auth/AuthHandler'; // Adjust path as needed

function JobContainer({ title }) {
  const { jobId } = useParams(); // Get jobId from URL
  const apiClient = useApiClient();
  const { userEmail } = useAuth();
  const [receiverEmail, setReceiverEmail] = useState(null);
  const [existingJob, setExistingJob] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Only run the data fetch logic when the title is either 'view-job' or 'update-job'
    const fetchJobData = async () => {
      if ((title === 'view-job' || title === 'update-job') && jobId) {
        console.log(`Fetching data for jobId: ${jobId} with title: ${title}`);

        try {
          // Log to check jobId and title are correctly set
          console.log(`Making API request to /jobs/${jobId}`);

          const response = await apiClient.get(`/jobs/${jobId}`);

          if (response && response.data) {
            console.log('API Response Data:', response.data);
            setExistingJob(response.data);

            // Determine the receiver email based on job data
            if (response.data.assignedTo === userEmail) {
              setReceiverEmail(response.data.createdBy);
            } else {
              setReceiverEmail(response.data.assignedTo);
            }
          } else {
            console.error('No data returned from API for jobId:', jobId);
          }

        } catch (error) {
          console.error('Error fetching job data. Please contact IT support. Error:', error);
        } finally {
          setLoading(false); // Stop loading after data fetch or error
        }
      } else {
        // If it's a create job, just set loading to false
        setLoading(false);
      }
    };

    // Run the function only if jobId exists
    if (jobId) {
      fetchJobData();
    } else {
      console.log('No jobId in URL, skipping fetch.');
      setLoading(false);
    }
  }, [jobId, title]); // Dependencies: jobId and title

  // If data is still loading, show a loading message
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='job-container'>
      <div className={`job-form-container ${title}`}>
        <JobForm title={title} jobInfo={existingJob} jobId={jobId} />
      </div>

      {/* Only show chat if title is 'view-job' and existingJob is present */}
      {existingJob && title === 'view-job' && (
        <div className='job-discussion-container'>
          <Chat senderEmail={userEmail} jobId={jobId} receiverEmail={receiverEmail} />
        </div>
      )}
    </div>
  );
}

export default JobContainer;
