import React, { useEffect, useState } from 'react';
import '../../styling/EmlViewer.css'; // Optional: Add specific styles

const EmlViewer = ({ fileUrl }) => {
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEml = async () => {
            setLoading(true);
            try {
                const response = await fetch(fileUrl);
                if (!response.ok) throw new Error('Network response was not ok');
                const text = await response.text();

                // Decode based on content type if specified
                const decodedString = decodeEmlContent(text);
                setContent(decodedString);
            } catch (error) {
                setError('Error fetching EML file: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (fileUrl) {
            fetchEml();
        }
    }, [fileUrl]);

    const decodeEmlContent = (data) => {
        // Check for base64 encoding
        const isBase64 = data.includes('Content-Transfer-Encoding: base64');
        if (isBase64) {
            const base64Match = data.match(/Content-Transfer-Encoding: base64\s+([\s\S]*?)(?=\n\n|\r\n\r\n)/);
            if (base64Match && base64Match[1]) {
                const base64Data = base64Match[1].replace(/\s/g, ''); // Remove whitespace
                try {
                    return atob(base64Data);
                } catch (error) {
                    setError('Error decoding base64 data: ' + error.message);
                    return '';
                }
            }
        }
        // Clean up the plain text
        return formatPlainText(data);
    };

    const formatPlainText = (text) => {
        // Basic cleanup: Replace multiple spaces/newlines with a single space/newline
        return text
            .replace(/\r?\n/g, '<br />') // Replace newlines with <br />
            .replace(/\s+/g, ' ')        // Replace multiple spaces with a single space
            .trim();                     // Trim leading and trailing whitespace
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div style={{ color: 'red' }}>{error}</div>;

    return (
        <div>
            {content && (
                <div
                    className='eml-viewer'
                    dangerouslySetInnerHTML={{ __html: content }} // Render HTML
                    style={{ whiteSpace: 'pre-wrap', width: '100%', height: 'auto', border: 'none' }}
                    title="EML Viewer"
                />
            )}
        </div>
    );
};

export default EmlViewer;
