import React from 'react';
import { useAuth } from '../../../auth/AuthHandler';

function JobTable({ jobs, onDeleteButton, onViewButton }) {
  const { userRoles } = useAuth();

  return (
    <table className="table table-bordered mt-3">
      <thead className="table-secondary">
        <tr>
          <th scope="col">Job Id</th>
          <th scope="col">Created By</th>
          <th scope="col">Assign To</th>
          <th scope="col">Status</th>
          <th scope="col">Created On</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {jobs.length === 0 ? (
          <tr>
            <td colSpan="6" className="text-center">No jobs available</td>
          </tr>
        ) : (
          jobs.map((job) => (
            <tr key={job.id}>
              <td>{job.id}</td>
              <td>{job.createdBy}</td>
              <td>{job.assignedTo}</td>
              <td>{job.status}</td>
              <td>{new Date(job.createdDate).toLocaleDateString()}</td>
              <td>
                <div className="d-flex">
                  {['SALESPERSON', 'MANAGER', 'ADMIN'].some(role => userRoles.includes(role)) &&
                  (
                    <>
                      <button className="btn btn-warning button-update ms-2" onClick={() => onViewButton(job.id)}>Preview</button>
                      <button className="btn btn-danger button-delete ms-2" onClick={() => onDeleteButton(job.id)}>Delete</button>
                    </>
                  )}
                  {['DATAENTRY', 'ADMIN'].some(role => userRoles.includes(role)) &&
                  (
                    <button className="btn btn-info button-view ms-2" onClick={() => onViewButton(job.id)}>View</button>
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default JobTable;
