import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faEdit } from '@fortawesome/free-solid-svg-icons';
import NotificationModal from '../NotificationModal';
import DOMPurify from 'dompurify';
import '../../../styling/ChatRoom.css';
import { useApiClient } from '../../../service/API';
import useWebSocket from '../../../hooks/useWebSocketHook';

const Chat = ({ jobId, senderEmail, receiverEmail }) => {
    const apiClient = useApiClient();
    // const [stompClient, setStompClient] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [editingMessageId, setEditingMessageId] = useState(null);
    const [hoveredMessageId, setHoveredMessageId] = useState(null);

    const messageDisplayRef = useRef(null);
    const textAreaRef = useRef(null);

    // Fetch chat history
    const fetchHistoryMessageData = useCallback(async () => {
        if (!jobId) return;
        try {
            const response = await apiClient.get(`/jobs/chat/${jobId}`);
            setMessages(response.data);
        } catch (error) {
            const errorMessage = error.response?.data?.businessExceptionDescription || 'Error fetching job data. Please try again.';
            setNotificationMessage(errorMessage);
            setShowNotification(true);
        }
    }, [jobId, apiClient]);

    useEffect(() => {
        fetchHistoryMessageData();
    }, []);

    const handleNewMessage = (msg) => {
        setMessages((prevMessages) => {
          const existingMessages = prevMessages.filter(message => message.msgId !== msg.msgId);
          return [...existingMessages, msg];
        });

        if (msg.createdBy !== senderEmail && msg.msgId) {
            apiClient.put(`/jobs/chat/update-read-status/${msg.msgId}`);
        }
    };

    const handleConnectionError = (error) => {
        console.error('WebSocket connection error:', error);
        setNotificationMessage('WebSocket connection error.');
        setShowNotification(true);
    };

    const updateMessageReadStatus = (message) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.msgId === message.msgId ? { ...msg, messageRead: message.messageRead } : msg
            )
        );
    };

    // Use the custom WebSocket hook
    const { stompClient, isConnected } = useWebSocket(senderEmail, jobId, null, handleNewMessage, handleConnectionError, updateMessageReadStatus);

    const sendMessage = () => {
        if (stompClient && inputMessage.trim()) {
          const message = { senderEmail, receiverEmail, jobId, content: inputMessage };
          stompClient.send(`/app/job/${jobId}`, {}, JSON.stringify(message));
          setInputMessage('');
          textAreaRef.current.innerHTML = '';
          scrollToBottom();
        } else {
          setNotificationMessage('Message cannot be empty.');
          setShowNotification(true);
        }
    };

    const scrollToBottom = () => {
        if (messageDisplayRef.current) {
            messageDisplayRef.current.scrollTop = messageDisplayRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const handlePaste = async (event) => {
        const items = event.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.startsWith('image/')) {
                await handleImagePaste(item.getAsFile());
                event.preventDefault();
            }
        }
    };

    const handleImagePaste = async (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const img = document.createElement('img');
            img.src = e.target.result;
            img.style.maxWidth = '100%';

            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            range.insertNode(img);

            range.setStartAfter(img);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            textAreaRef.current.focus();

            const formData = new FormData();
            const filenameWithJobId = `${jobId}_${file.name}`;
            formData.append("filename", new Blob([file], { type: file.type }), filenameWithJobId);

            try {
                const response = await apiClient.post('/files/uploadchatimg', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                const uploadedImageUrl = response.data;
                img.src = uploadedImageUrl;
                setInputMessage(prevMessage => prevMessage + `<img src="${uploadedImageUrl}" style="max-width:100%;" />`);
            } catch (error) {
                setNotificationMessage('Error uploading image.');
                setShowNotification(true);
            }
        };

        reader.readAsDataURL(file);
    };

    const handleEdit = (messageId, currentContent) => {
        setEditingMessageId(messageId);
        setInputMessage(currentContent);
    };

    const handleUpdateMessage = async (messageId) => {
        const updatedMessage = { id: messageId, content: inputMessage, jobId: jobId };

        try {
            await apiClient.put('/jobs/chat/update-message', updatedMessage);
            setMessages((prevMessages) =>
                prevMessages.map(msg =>
                    msg.msgId === messageId ? { ...msg, content: inputMessage } : msg
                )
            );
            setInputMessage('');
            setEditingMessageId(null);
        } catch (error) {
            setNotificationMessage('Error updating message.');
            setShowNotification(true);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return '';

        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).format(date);
    };

    return (
        <div className="chat-room">
            <h2>Chat Room for Job ID: {jobId}</h2>
            <div className="message-display" ref={messageDisplayRef}>
                {messages.map((msg) => (
                    <div
                        className={`message ${msg.createdBy === senderEmail ? 'sender' : 'receiver'}`}
                        key={msg.msgId}
                        onMouseEnter={() => setHoveredMessageId(msg.msgId)}
                        onMouseLeave={() => setHoveredMessageId(null)}
                    >
                        {editingMessageId === msg.msgId ? (
                            <EditMessage
                                currentContent={msg.content}
                                onUpdate={() => handleUpdateMessage(msg.msgId)}
                                onCancel={() => setEditingMessageId(null)}
                                inputMessage={inputMessage}
                                setInputMessage={setInputMessage}
                            />
                        ) : (
                            <DisplayMessage
                                msg={msg}
                                senderEmail={senderEmail}
                                onEdit={() => handleEdit(msg.msgId, msg.content)}
                                isHovered={hoveredMessageId === msg.msgId}
                                formatDate={formatDate}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="input-area">
                <div
                    contentEditable
                    className="form-control chat-input-area"
                    ref={textAreaRef}
                    onInput={(e) => setInputMessage(e.currentTarget.innerHTML)}
                    onPaste={handlePaste}
                    onKeyPress={handleKeyPress}
                />
                <button
                    type="button"
                    className='btn btn-primary'
                    onClick={sendMessage}
                    disabled={!jobId}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} />
                    Send
                </button>
            </div>

            {/* Notification Modal */}
            <NotificationModal
                show={showNotification}
                message={notificationMessage}
                onClose={() => setShowNotification(false)}
            />
        </div>
    );
};

const EditMessage = ({ currentContent, onUpdate, onCancel, inputMessage, setInputMessage }) => (
    <div className="edit-chat-box">
        <div className='cancel-edit' onClick={onCancel}>x</div>
        <div
            contentEditable
            className="form-control-edit-msg"
            onInput={(e) => setInputMessage(e.currentTarget.innerHTML)}
            placeholder="Edit your message..."
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentContent) }}
        />
        <button onClick={onUpdate}>
            <FontAwesomeIcon icon={faPaperPlane} />
        </button>
    </div>
);

const DisplayMessage = ({ msg, senderEmail, onEdit, isHovered, formatDate }) => (
    <>
        {isHovered && msg.createdBy === senderEmail && (
            <button className="edit-button" onClick={onEdit}>
                <FontAwesomeIcon icon={faEdit} />
            </button>
        )}
        <strong>{msg.createdBy}: {formatDate(msg.createdDate)}</strong>
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.content) }} />
        <span className={`read-status ${msg.messageRead ? '' : 'unread'}`}>
            {msg.messageRead ? '✓ Read' : '✗ Unread'}
        </span>
    </>
);

export default Chat;
