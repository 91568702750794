// src/hooks/useWebSocket.js
import { useEffect, useState, useCallback } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { API_BASE_URL, ACCESS_TOKEN } from '../constants';

const useWebSocket = (userEmail, jobId, onMessageReceivedForAlert, onMessageReceived, onConnectionError, updateMessageReadStatus) => {
    const [stompClient, setStompClient] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const connectWebSocket = useCallback(() => {
      const token = localStorage.getItem(ACCESS_TOKEN);
      const socket = new SockJS(`${API_BASE_URL}/chat?token=${token}`);
      const client = Stomp.over(() => socket);

      client.connect({}, (frame) => {
        console.log('Connected to WebSocket:', frame);
        setIsConnected(true);

        if (userEmail) {
          client.subscribe(`/topic/alert/${userEmail}`, (message) => {
            const msg = JSON.parse(message.body);
            if (typeof onMessageReceivedForAlert === 'function') {
              onMessageReceivedForAlert(msg);
            }
          });
        }

        if (jobId) {
          client.subscribe(`/topic/job/${jobId}`, (message) => {
            const msg = JSON.parse(message.body);
            if (typeof onMessageReceived === 'function') {
                onMessageReceived(msg);
            }
          });
        }

        client.subscribe(`/topic/msg-read/${jobId}`, (message) => {
            const msg = JSON.parse(message.body);
            if (typeof onMessageReceivedForAlert === 'function') {
                onMessageReceivedForAlert(msg);
            }
            if (typeof updateMessageReadStatus === 'function') {
                updateMessageReadStatus(msg);
            }
        });

        client.onclose = () => {
          console.error('WebSocket connection closed.');
          setIsConnected(false);
          setTimeout(connectWebSocket, 5000); // Attempt to reconnect
        };
      }, (error) => {
        console.error('Error connecting to WebSocket:', error);
        onConnectionError(error);
        setIsConnected(false);
        setTimeout(connectWebSocket, 5000); // Attempt to reconnect
      });

      setStompClient(client);
    }, [userEmail, jobId, onMessageReceived, onMessageReceivedForAlert, onConnectionError]);

    useEffect(() => {
      connectWebSocket();
      return () => {
        stompClient?.disconnect(); // Clean up on unmount
      };
    }, []);

    return { stompClient, isConnected };
  };

  export default useWebSocket;
