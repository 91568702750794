import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const UnauthorizedPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const message = location.state?.message || "Unauthorized - You do not have permission to view this page";
    
    // State to control access
    const [canNavigate, setCanNavigate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCanNavigate(true); // Allow navigation after 0.5 seconds
            navigate('/home'); // Redirect to /home after 0.5 seconds
        }, 500);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [navigate]);

    // Prevent navigation if canNavigate is false
    const handleNavigation = (e) => {
        if (!canNavigate) {
            e.preventDefault(); // Block navigation
        }
    };

    // Use the event listener to block navigation
    useEffect(() => {
        window.addEventListener('beforeunload', handleNavigation);

        return () => {
            window.removeEventListener('beforeunload', handleNavigation);
        };
    }, [canNavigate]);

    return (
        <div>
            <h1>{message}</h1>
            <p>You will be redirected shortly...</p>
        </div>
    );
};

export default UnauthorizedPage;
