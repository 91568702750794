import React, { useReducer, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useJobs from '../../../hooks/useJobs';
import useConfirmationModal from '../../../hooks/useConfirmationModal';
import FilterForm from './FilterForm';
import JobTable from './JobTable';
import PaginationControls from './PaginationControls';
import ConfirmationModal from '../ConfirmationModal';
import NotificationModal from '../NotificationModal'; // Import NotificationModal
import { useApiClient } from '../../../service/API';
import '../../../styling/JobList.css';

// Reducer for managing state
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_SIZE':
      return { ...state, pageSize: action.payload, currentPage: 0 };
    case 'SET_FILTERS':
      return { ...state, filters: action.payload, currentPage: 0 };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    case 'RESET_FILTERS':
      return { 
        ...state, 
        filters: { jobCode: '', createdBy: '', assignTo: '', status: '' },
        pageSize: 10,
        currentPage: 0 
      };
    default:
      return state;
  }
};

const JobList = () => {
  const apiClient = useApiClient();
  const [state, dispatch] = useReducer(reducer, {
    filters: { jobCode: '', createdBy: '', assignTo: '', status: '' },
    pageSize: 10,
    currentPage: 0
  });

  const {
    jobs,
    loading,
    totalPages,
    setExecuteFilter,
    error,
  } = useJobs(state.filters, state.pageSize, state.currentPage);

  const { showConfirm, deleteId, showModal, closeModal } = useConfirmationModal();
  const navigate = useNavigate();
  const [notificationMessage, setNotificationMessage] = useState(''); // Notification message

  // Handler functions
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    dispatch({ type: 'SET_FILTERS', payload: { ...state.filters, [name]: value } });
  };

  const handlePageSizeChange = (size) => {
    dispatch({ type: 'SET_PAGE_SIZE', payload: size });
  };

  const handleResetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
    setExecuteFilter(true);
  };

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      dispatch({ type: 'SET_CURRENT_PAGE', payload: page });
      setExecuteFilter(true);
    }
  };

  const handleViewButton = useCallback((id) => {
    navigate(`/view-job/${id}`);
  }, [navigate]);

  const handleDeleteButton = async () => {
    if (deleteId === null) return;
    try {
      const response = await apiClient.delete(`/jobs/delete-job/${deleteId}`);
      if (response.status === 400 && response.data.error.includes('No job found with the ID')) {
        // Refresh data if specific error is found
        setExecuteFilter(true);
      } else if (response.data.error && response.data.error.includes('Internal error, contact the admin')) {
        // Show notification for internal errors
        setNotificationMessage("Internal error, contact the admin");
      } else {
        setNotificationMessage("Job deleted successfully");
        setExecuteFilter(true);
      }
      closeModal();
    } catch (error) {
      console.error('Error deleting job:', error);
      setNotificationMessage("An error occurred. Please try again.");
      closeModal();
    }
  };

  useEffect(() => {
    if (error) {
      setNotificationMessage(error);
    }
  }, [error]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='job-list'>
      <FilterForm
        filters={state.filters}
        pageSize={state.pageSize}
        onFilterChange={handleFilterChange}
        onPageSizeChange={handlePageSizeChange}
        onResetFilters={handleResetFilters}
        onApplyFilters={() => setExecuteFilter(true)}
      />
      <JobTable
        jobs={jobs}
        onDeleteButton={showModal}
        onViewButton={handleViewButton}
      />
      <PaginationControls
        currentPage={state.currentPage}
        totalPages={totalPages}
        onPreviousPage={() => handlePageChange(state.currentPage - 1)}
        onNextPage={() => handlePageChange(state.currentPage + 1)}
      />
      <ConfirmationModal
        show={showConfirm}
        onClose={closeModal}
        onConfirm={handleDeleteButton}
        message="Are you sure you want to delete this job?"
        title="Confirm Deletion"
      />
      {/* Notification Modal */}
      <NotificationModal
        show={Boolean(notificationMessage)}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
        navigate={() => {}} // Adjust if needed for navigation
      />
    </div>
  );
};

export default JobList;
