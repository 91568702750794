import React, { useEffect } from "react";
import { GOOGLE_AUTH_URL } from "../../constants";
import { Navigate } from "react-router-dom";
import { toast } from 'react-toastify'; // Import toast from react-toastify

const Login = ({ authenticated, location }) => {

  useEffect(() => {
    // Handle OAuth2 errors
    if (location.state && location.state.error) {
      toast.error(location.state.error, { autoClose: 5000 }); // Use toast for errors
    }
  }, [location]);

  // Redirect to Google Auth URL if not authenticated
  if (!authenticated) {
    window.location.replace(GOOGLE_AUTH_URL); // Try using replace
    return null; // Prevent rendering the component
  }

  // Redirect to the home page if authenticated
  return <Navigate to="/home" />;
};

export default Login;
