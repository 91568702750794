import { API_BASE_URL, ACCESS_TOKEN } from "../constants";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode

const request = async (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {

    headers.append("Authorization", `Bearer ${token}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  try {
    const response = await fetch(options.url, options);
    const json = await response.json();

    if (!response.ok) {
      return Promise.reject(json);
    }

    return json;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCurrentUser = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/login`,
    method: "GET",
  });
};

export const logoutUser = async () => {
  try {
      const response = await fetch({
          url: `${API_BASE_URL}/logout`,
          method: "POST",
          credentials: "include", // Ensure cookies are included
      });

      if (!response.ok) {
          throw new Error(`Logout failed: ${response.statusText}`);
      }

      // Clear local and session storage on successful logout
      localStorage.clear();
      sessionStorage.clear();

      // Optionally, you can manually delete specific cookies if necessary
      document.cookie.split(";").forEach((cookie) => {
        const cookieName = cookie.split("=")[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  } catch (error) {
      console.error("Logout Error:", error); // Log the error
      // Optionally, you can show a notification to the user
  }
};



// Function to decode JWT and extract role and expiration
export const decodeJwt = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const decodedToken = jwtDecode(token);
  
  const authorities = decodedToken.authorities || [];
  const expiration = decodedToken.exp * 1000; // Convert to milliseconds
  const userEmail = decodedToken.sub;
  const userName = decodedToken.fullName;

  
  return { authorities, expiration, userEmail, userName };
};
