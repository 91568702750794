// DocxViewer.js
import React, { useEffect, useState } from 'react';
import * as mammoth from 'mammoth';
import '../../styling/DocxViewer.css'; // Optional: Add specific styles

const DocxViewer = ({ fileUrl }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchDocx = async () => {
      try {
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setHtmlContent(result.value);
      } catch (error) {
        console.error('Error fetching DOCX file:', error);
      }
    };

    fetchDocx();
  }, [fileUrl]);

  return (
    <div className="docx-viewer" dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default DocxViewer;
