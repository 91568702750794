import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../../styling/SearchableDropdown.css';

const SearchableDropdown = ({ options = [], selectedValue, onSelectedChange, placeholder, id }) => { // Default value for options
    const [searchValue, setSearchValue] = useState('');
    const [query, setQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const containerRef = useRef(null);

    // Update filtered options based on query
    useEffect(() => {
        setFilteredOptions(
            options.filter(option =>
                option.fullname.toLowerCase().includes(searchValue?.toLowerCase()) ||
                option.email.toLowerCase().includes(searchValue?.toLowerCase())
            )
        );
    }, [searchValue]);

    // Update query based on selectedValue
    useEffect(() => {
        const selectedOption = options.find(option => option.email === selectedValue);
        if (selectedOption) {
            setQuery(selectedOption.fullname);
        } else {
            setQuery('');
        }
    }, [selectedValue, options]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (option) => {
        onSelectedChange(option.email);
        setQuery(option.fullname);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <div className="searchable-dropdown" ref={containerRef} aria-haspopup="true" aria-expanded={isOpen}>
            <input
                onClick={toggleDropdown}
                id={id}
                type="text"
                className="form-select"
                placeholder={placeholder}
                value={query}
                readOnly
                aria-autocomplete="list"
                aria-controls="dropdown-list"
                autoComplete="off" // Set to off to avoid autofill when opening the dropdown
            />
            {isOpen && (
                <ul className="dropdown-menu" id="dropdown-list" aria-labelledby="assign-to">
                    <li>
                        <div className="dropdown-search-container">
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            <input
                                type="text"
                                className="form-control dropdown-search-area"
                                placeholder="Search..."
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                aria-label="Search"
                                autoComplete="off" // Set to off for the search input
                                id={`${id}-search`} // Unique ID based on the parent ID
                                name={`${id}-search`} // Unique Name based on the parent ID
                            />
                        </div>
                    </li>
                    {searchValue === '' ? (
                        options.map((option) => (
                            <li
                                key={option.email}
                                className={`dropdown-item ${option.email === selectedValue ? 'active' : ''}`}
                                onClick={() => handleSelect(option)}
                            >
                                {option.fullname}
                            </li>
                        ))
                    ) : (
                        filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <li
                                    key={option.email}
                                    className={`dropdown-item ${option.email === selectedValue ? 'active' : ''}`}
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.fullname}
                                </li>
                            ))
                        ) : (
                            <li className="dropdown-item">No options available</li>
                        )
                    )}
                </ul>
            )}
        </div>
    );
};

// PropTypes for validation
SearchableDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            fullname: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        })
    ),
    selectedValue: PropTypes.string.isRequired,
    onSelectedChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default SearchableDropdown;
