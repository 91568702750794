import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../service/API';
import SearchableDropdown from '../JobHandling/SearchableDropdown';

const ManageUserPage = () => {
    const apiClient = useApiClient();
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [userDetailList, setUserDetailList] = useState([]);

    useEffect(() => {
        // Fetch the existing users
        loadUserDetailList();
    }, []);

    const loadUserDetailList = async () => {
        try {
            const response = await apiClient.get('/users/detail-list');
            setUserDetailList(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSelectionChange = async (selectedEmail) => {
        setEmail(selectedEmail);
    
        // Fetch roles for the selected user
        const selectedUser = userDetailList.find(user => user.email === selectedEmail);
        if (selectedUser && selectedUser.roles) {
            // Ensure selectedUser.role is an array before mapping
            setRoles(selectedUser.roles.map(r => r.name));
        } else {
            setRoles([]); // Reset roles if user or roles are undefined
        }
    };
    

    const handleRoleChange = (event) => {
        const { value, checked } = event.target;
        setRoles(prevRoles =>
            checked ? [...prevRoles, value] : prevRoles.filter(role => role !== value)
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');

        const payload = {
            selectedUser: email,
            role: roles
        };

        try {
            await apiClient.put('/users/manage', payload);
            setMessage('User roles updated successfully!');
        } catch (err) {
            setError('Error updating user roles. Please try again.');
            console.error(err);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Manage User Roles</h1>
            {message && <div className="alert alert-success">{message}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">User Email</label>
                    <SearchableDropdown
                        id="email"
                        options={userDetailList}
                        selectedValue={email}
                        onSelectedChange={handleSelectionChange}
                        placeholder="Select user"
                    />
                </div>
                <fieldset className="mb-3">
                    <legend>Roles</legend>
                    {['USER', 'ADMIN', 'MANAGER', 'SALESPERSON', 'DATAENTRY'].map(role => (
                        <div className="form-check" key={role}>
                            <input
                                type="checkbox"
                                id={`role${role}`}
                                className="form-check-input"
                                value={role}
                                checked={roles.includes(role)}
                                onChange={handleRoleChange}
                            />
                            <label htmlFor={`role${role}`} className="form-check-label">{role}</label>
                        </div>
                    ))}
                </fieldset>
                <button type="submit" className="btn btn-primary">Update Roles</button>
            </form>
        </div>
    );
};

export default ManageUserPage;
