import React from 'react';

function PaginationControls({ currentPage, totalPages, onPreviousPage, onNextPage }) {
  return (
    <div className="pagination-controls mb-3">
      <button
        className="btn btn-secondary"
        onClick={onPreviousPage}
        disabled={currentPage === 0}
      >
        Previous
      </button>
      <p>Page {currentPage + 1} of {totalPages}</p>
      <button
        className="btn btn-secondary ms-2"
        onClick={onNextPage}
        disabled={currentPage >= totalPages - 1}
      >
        Next
      </button>
    </div>
  );
}

export default PaginationControls;
