import React from 'react';
import '../../styling/Modal.css';

function FileUploadingModal({ isUploading }) {

  return (
    <div
      className={`modal fade ${isUploading ? 'show modal-backdrop-transparent' : ''}`}
      tabIndex="-1"
      aria-labelledby="notificationModalLabel"
      aria-hidden={!isUploading}
      role="dialog"
      style={{ display: isUploading ? 'block' : 'none' }} // Inline style to control visibility
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="fileUploadModalLabel">File Upload Process</h5>
          </div>
          <div className="modal-body">
            Upload process ongoing...
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileUploadingModal;
