import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthHandler';

function FilterForm({ filters, pageSize, onFilterChange, onPageSizeChange, onResetFilters, onApplyFilters }) {
    const navigate = useNavigate();
    const { userRoles } = useAuth();

    return (
        <form className="mb-3">
        <div className="row g-3">
            <div className="col-md-6 col-lg-3">
            <label htmlFor="jobCode" className="form-label">Job Code</label>
            <input
                type="text"
                id="jobCode"
                name="jobCode"
                placeholder="Job Code"
                value={filters.jobCode}
                onChange={onFilterChange}
                className="form-control"
            />
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="createdBy" className="form-label">Created By</label>
            <input
                type="text"
                id="createdBy"
                name="createdBy"
                placeholder="Created By"
                value={filters.createdBy}
                onChange={onFilterChange}
                className="form-control"
            />
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="assignTo" className="form-label">Assign To</label>
            <input
                type="text"
                id="assignTo"
                name="assignTo"
                placeholder="Assign To"
                value={filters.assignTo}
                onChange={onFilterChange}
                className="form-control"
            />
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="status" className="form-label">Status</label>
                <select
                    id="status"
                    className="form-select"
                    name="status"
                    placeholder="Status"
                    value={filters.status}
                    onChange={onFilterChange}
                    >
                    <option value="">All</option>
                    <option value="NEW">NEW</option>
                    <option value="URGENT">URGENT</option>
                    <option value="ERROR">ERROR</option>
                    <option value="COMPLETE">COMPLETE</option>
                    <option value="DONE">DONE</option>
                    <option value="CANCELLED">CANCELLED</option>
                </select>
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="dropdownMenuButton" className="form-label">Page Size</label>
            <div className="dropdown custom-dropdown">
                <button className="btn btn-outline-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                Page Size: {pageSize}
                <i className="bi bi-chevron-down ms-2"></i>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(10)}>10</a></li>
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(25)}>25</a></li>
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(50)}>50</a></li>
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(100)}>100</a></li>
                </ul>
            </div>
            </div>
                <div className="row gap-2 me-md-2 mt-2 ms-auto">
                    <button
                        type="button"
                        className="btn btn-primary col-md-2"
                        onClick={onApplyFilters}
                    >
                        Apply Filters
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary col-md-2"
                        onClick={onResetFilters}
                    >
                        Reset Filters
                    </button>
                    {['SALESPERSON', 'MANAGER', 'ADMIN'].some(role => userRoles.includes(role))&&<button
                    type="button"
                    className="btn btn-primary col-md-2 ms-auto"
                    onClick={() => navigate('/create-job')}
                    >
                    Create Job
                    </button>}
                </div>
            </div>
        </form>
    );
}

export default FilterForm;
