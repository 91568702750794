import axios from 'axios';
import { ACCESS_TOKEN, API_BASE_URL } from '../constants';

// Custom hook for API client
export function useApiClient() {

  // Create an Axios instance with the base URL
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    }
  });

  // Add a request interceptor to include JWT token in headers
  apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor to handle errors
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        window.location.reload(); // Refresh the page
      }
      return Promise.reject(error);
    }
  );

  return apiClient;
}
