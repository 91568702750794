import React from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute.js";

const PrivateRoute = ({userRoles, roles, element, authenticated }) => {
  // to-do change to unauthorized
  return authenticated ? 
  <ProtectedRoute roles={roles} children={element} userRoles={userRoles} /> 
  : 
  <Navigate to="/login" />;
};

export default PrivateRoute;
