// ExcelViewer.js
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import '../../styling/ExcelViewer.css'; // Optional: Add specific styles

const ExcelViewer = ({ fileUrl }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getGoogleDocsViewerUrl = (signedUrl) => {
      return `https://docs.google.com/gview?url=${encodeURIComponent(signedUrl)}&embedded=true`;
    };  

    useEffect(() => {
        const fetchExcel = async () => {
        try {
            setLoading(true);
            const response = await fetch(fileUrl);
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetNames = workbook.SheetNames;
            const firstSheet = sheetNames[0];
            const worksheet = workbook.Sheets[firstSheet];
            const html = XLSX.utils.sheet_to_html(worksheet);
            setHtmlContent(html);
            setError(null);
        } catch (error) {
            console.error('Error fetching Excel file:', error);
            setError('Failed to read the Excel file.');
        }finally {
            setLoading(false);
        }
        };

        fetchExcel();
    }, [fileUrl]);

  if (loading) {
    return <div className="excel-viewer">Loading...</div>;
  }

  if (error) {
    return <div className="excel-viewer error">{error}</div>;
  }

  if (!htmlContent) {
    return <div className="excel-viewer">No data to display</div>;
  }

  return (
    <div className="excel-viewer" dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default ExcelViewer;
